.consultant {
    display: flex;
}

.eyes {
    @apply object-cover
    hidden
        lg:block;
    width: 25%;
}

.consultant-text-container {
    @apply
    pr-[24px]
        lg:pr-[88px]
    w-screen
        lg:w-3/4;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 24px;
    padding-top: 56px;
    padding-bottom: 56px;
}

.consultant-title {
    @apply
    font-playfair
    text-dark-blue
    tracking-wide;
    font-size: 32px;
    font-weight: 400;
    padding-bottom: 28px;
    border-bottom: solid 2px #40798C;
}

.consultant-body {
    @apply
    text-dark-blue;
    padding-top: 28px;
    font-weight: 300;
    letter-spacing: 0.05em;
}

.button {
    @apply whitespace-nowrap;
    text-align: left;
    margin-top: 28px;
}

.learn-more-btn {
    @apply
    bg-beige
    font-sans
    text-dark-blue
    px-9
        lg:px-20
    py-2
        lg:py-4;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 400;
}

.learn-more-btn:hover {
    @apply
    text-light-blue
    bg-dark-blue;
    transition: background .8s;
}
