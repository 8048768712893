.about-me {
    @apply
    bg-beige
    p-8;
    display: flex;
}

.about-me-image {
    @apply
    hidden
        md:block;
    width: 50%;
    object-fit: cover;
}

.about-me-text-container {
    @apply
    pl-0
        md:pl-[88px]
    w-screen
        md:w-3/4;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.about-me-title {
    @apply
    font-playfair
    text-dark-blue
    tracking-wide;
    font-size: 32px;
    font-weight: 400;
    padding-bottom: 28px;
    border-bottom: solid 2px #40798C;
}

.about-me-body {
    @apply
    text-dark-blue;
    padding-top: 28px;
    font-weight: 300;
    letter-spacing: 0.05em;
}

.learn-more-about-btn {
    @apply
    bg-beige
    font-sans
    text-dark-blue
    border-2
    border-dark-blue;
    border-radius: 100px;
    padding: 14px 86px;
    font-size: 16px;
    font-weight: 400;
}

.learn-more-about-btn:hover {
    @apply
    text-light-blue
    bg-dark-blue;
    transition: background .8s;
}
