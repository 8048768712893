.sign-up {
    @apply bg-beige flex;
}

/*make this in a container and then do a width so it isnt stretched*/
.sign-up-image {
    @apply
    object-cover
    hidden
        md:block;
    width: 50%;
}

.submitted-message {
    @apply
    text-dark-blue;
    padding-top: 28px;
    padding-bottom: 28px;
    font-weight: 300;
    letter-spacing: 0.05em;
}

.sign-up-text-container {
    @apply
    px-8
    w-full
        md:w-3/4
    pr-8
    pt-[56px]
    pb-8;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.sign-up-title {
    @apply
    font-playfair
    text-dark-blue
    tracking-wide;
    font-size: 32px;
    font-weight: 400;
    padding-bottom: 28px;
    border-bottom: solid 2px #40798C;
}

.sign-up-body {
    @apply
    text-dark-blue;
    padding-top: 28px;
    padding-bottom: 28px;
    font-weight: 300;
    letter-spacing: 0.05em;
}

.sign-up-button {
    text-align: left;
    margin-top: 28px;
}

.sign-up-btn {
    @apply
    bg-light-blue
    text-dark-blue;
    border-radius: 100px;
    padding: 14px 86px;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
}

.sign-up-btn:hover {
    @apply
    text-light-blue
    bg-dark-blue;
    transition: background .8s;
}

.text-field {
    @apply bg-light-blue;
    padding: 14px 24px;
    width: 100%;
    margin-bottom: 48px;
    border-radius: 100px;
    border: solid 2px #40798C;
}

#message {
    @apply bg-light-blue;
    padding: 14px 24px;
    /*height: 100px;*/
    width: 100%;
    margin-bottom: 48px;
    border-radius: 20px;
    border: solid 2px #40798C;
    text-align: start;
}

