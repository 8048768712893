.blocks {
    display: flex;
}

.media-blocks {
    @apply bg-beige flex}

.therapy-professionals-text-container {
    @apply
    mx-8
    pb-8
    w-full
        xl:w-3/4;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 56px;
}

.therapy-professionals-title {
    @apply
    font-playfair
    text-dark-blue
    tracking-wide;
    font-size: 32px;
    font-weight: 400;
    padding-bottom: 28px;
    border-bottom: solid 2px #40798C;
}

.therapy-professionals-body {
    @apply
    text-dark-blue;
    padding-top: 28px;
    padding-bottom: 28px;
    font-weight: 300;
    letter-spacing: 0.05em;
}

.image-side-panel-blocks {
    @apply
    w-0
        xl:w-1/4
    hidden
        xl:block;
    overflow: hidden;
    object-fit: cover;
}

.topic-sections {
    padding-top: 56px;
    padding-bottom: 88px;
}

.topic-sections-column {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.topic-sections-row {
    @apply
    gap-0
        md:gap-[12px]
    flex-col
        xl:flex-row;
    display: flex;
}

.consulting-section {
    @apply text-light-blue;
    margin-bottom: 12px;
    position: relative;
}

.coaching-section {
    @apply text-light-blue;
    margin-bottom: 12px;
    position: relative;
}

.graduate-section {
    @apply text-light-blue;
    margin-bottom: 12px;
    position: relative;
}

.publications-section {
    @apply text-light-blue;
    margin-bottom: 12px;
    position: relative;
}

.section-text {
    position: absolute;
    z-index: 5;
    top: 0;
    padding: 24px;
    width: 100%;
    height: 100%;
}

.section-title {
    @apply
    text-xl
        md:text-2xl
    pb-1
        md:pb-4
    font-sans;
    font-weight: 400;
    border-bottom: solid 2px #E0EAEE;
}

.section-body {
    @apply
    text-[16px]
        md:text-[18px]
    pt-4;
    font-weight: 300;
    letter-spacing: 0.05em;
}

.image-block {
    position: relative;
}

.button-blocks {
    @apply
    mt-8
        md:mt-4;
    text-align: left;
}



