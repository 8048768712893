.therapy-professionals {
    display: flex;
}

.therapy-professionals-title {
    @apply
    font-playfair
    text-dark-blue
    tracking-wide;
    font-size: 32px;
    font-weight: 400;
    padding-bottom: 28px;
    border-bottom: solid 2px #40798C;
}

.therapy-professionals-body {
    @apply
    text-dark-blue;
    padding-top: 28px;
    padding-bottom: 28px;
    font-weight: 300;
    letter-spacing: 0.05em;
}

.image-side-panel {
    width: 25%;
    overflow: hidden;
    object-fit: cover;
}

.topic-sections {
    padding-top: 56px;
    padding-bottom: 88px;
}

.consulting-section {
    @apply text-light-blue;
    margin-bottom: 12px;
    position: relative;
}

.coaching-section {
    @apply text-light-blue;
    margin-bottom: 12px;
    position: relative;
}

.graduate-section {
    @apply text-light-blue;
    margin-bottom: 12px;
    position: relative;
}

.publications-section {
    @apply text-light-blue;
    margin-bottom: 12px;
    position: relative;
}

.section-text {
    position: absolute;
    z-index: 5;
    top: 0;
    padding: 24px;
}

.section-title {
    @apply
    font-sans;
    font-size: 24px;
    font-weight: 400;
    padding-bottom: 24px;
    border-bottom: solid 2px #E0EAEE;
}

.section-body {
    padding-top: 24px;
    font-weight: 300;
    letter-spacing: 0.05em;
}

.image-block {
    @apply
    w-full
    rounded-[20px]
    object-cover
    h-[300px]
        md:h-auto
        lg:h-[300px];
    z-index: 0;
    position: relative;
}



