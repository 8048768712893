.banner {
    @apply text-center bg-beige;
}

.banner-topics {
    @apply
    font-playfair
    text-dark-blue
    flex-col
        md:flex-row
    tracking-wide;
    font-size: 32px;
    display: flex;
    justify-content: space-evenly;
    padding: 48px;
}

.leaf-left {
    @apply
    py-2
        md:py-0;
    width: 32px;
}

.leaf-right {
    @apply
    py-2
        md:py-0;
    width: 32px;
}

.leaf {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 48px;
    padding-right: 48px;
}
