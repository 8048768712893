.hero {
    position: relative;
    display: flex;
}

.hero-image {
    @apply
    h-auto
        lg:h-[600px];
    object-fit: cover;
    object-position: 200% 10%;
    width: 100vw;
    overflow: hidden;
    z-index: 1;
    top: 0;
}

.hero-image-layer {
    @apply
    bg-light-blue
    h-full
    lg:h-[600px];
    width: 100vw;
    position: absolute;
    z-index: 2;
    opacity: 25%;
}

.hero-welcome {
    @apply
    bg-light-blue
    py-6
        lg:py-20
    pl-12
        lg:pl-20
    mt-24
        sm:mt-0
    my-6
        sm:my-36
        md:my-56
        lg:my-20
    ml-0
        lg:ml-20
    w-screen
        lg:w-1/3;
    top: 0;
    z-index: 2;
    max-width: 700px;
    position: absolute;
    opacity: 85%;
}

.hero-welcome-text {
    display: flex;
    align-items: center;
    align-content: center;
}

.quote-by {
    @apply
    pt-2
        lg:pt-[28px]
    text-[12px]
        lg:text-[16px]
    italic;
    font-weight: 300;
    letter-spacing: 0.05em;
}

.hero-welcome-title {
    @apply
    font-playfair
    text-dark-blue
    tracking-wide;
    font-size: 32px;
    font-weight: 400;
    padding-right: 0.5em;
}

.title-line {
    border: solid 1px #40798C;
    flex: 2;
    height: 1px;
    justify-content: center;
}

.quote-body {
    @apply
    font-sans
    text-[16px]
        lg:text-[24px]
    text-dark-blue;
    font-weight: 300;
}

.hero-welcome-body {
    display: flex;
}

.hero-welcome-body-text {
    @apply
    mr-12
        lg:mr-20
    mt-2;
    text-align: left;
}


