.publications {
    display: flex;
}

.pub-container {
    @apply
    mb-16
}

.path-image {
    height: 100%;
    @apply
    object-cover
}

.book {
    height: 100%;
    @apply
    object-cover
}

.book-media {
    @apply
    object-cover
}

.publications {
    height: 36rem;
}
