.badges-container {
    display: flex;
    padding-top: 48px;
}

.badges {
    padding-right: 48px;
    width: 124px;
}

.badges-container-home {
    display: flex;
}

.badges-home {
    padding-right: 24px;
    width: 100px;
}
