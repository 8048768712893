.footer {
    @apply
    flex
    px-8
    py-8
    items-center
        sm:items-start
    flex-col
        sm:flex-row;
    justify-content: space-between;
}

.name-logo-container {
    @apply
    pb-6
        sm:pb-0
    text-[24px]
        sm:text-[32px]
    font-playfair
    text-dark-blue
    tracking-wide;
    font-weight: 400;
    display: flex;
    align-items: flex-start;
}

.footer-name-logo {
    display: flex;
    align-items: center;
}

.footer-name {
    margin-left: 24px;
}

.maze-icon-footer {
    @apply
    w-[32px]
        sm:w-[56px];
}

.listed-links-container {
    display: flex;
    text-align: end;
}

.footer-links {
    @apply
    font-sans
    text-dark-blue;
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
}

.footer-item {
    margin-bottom: 16px;
    border-bottom: solid 1px #40798C;
}

.footer-item:hover {
    @apply bg-dark-blue
    text-light-blue;
    transition: background .8s;
}
