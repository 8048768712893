header {
    @apply
    text-dark-blue
    bg-light-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.maze-icon {
    margin-top: 34px;
    margin-bottom: 34px;
}

.website-title {
    @apply
    font-playfair
    text-dark-blue
    pb-8
        md:pb-0
    tracking-wide;
    font-size: 32px;
    font-weight: 400;
}

.nav {
    display: flex;
    text-transform: uppercase;
    margin-top: 26px;
    margin-bottom: 26px;
}

.navbar {
    @apply
    flex
    justify-end
        md:justify-center
    w-screen
    pr-8
    py-6
        md:py-0
    items-center
    absolute
        md:relative
    top-0
}

.hamburger-menu {
    @apply
    bg-light-blue/25
    block
        md:hidden;
}

.nav-item {
    @apply
    hidden
        md:block
    font-sans
    text-dark-blue;
    font-size: 16px;
    font-weight: 300;
    padding-left: 12px;
    padding-right: 12px;
}

.nav__link__mobile {
    @apply
    font-sans
    text-dark-blue;
    font-size: 16px;
    font-weight: 300;
    padding-left: 12px;
    padding-right: 12px;
}

.nav-item:not(:first-child) {
    margin-left: 2em;
}

.nav-item:hover {
    @apply bg-dark-blue
    text-light-blue;
    cursor: pointer;
    transition: background .8s;
}
