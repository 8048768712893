.resource-title {
    @apply
    font-sans
    text-dark-blue
    mt-16;
    font-size: 24px;
    font-weight: 400;
    padding-bottom: 24px;
}

.resources-container {
    @apply bg-beige flex;
}

.resource-button {
    @apply
    whitespace-nowrap
    bg-beige
    font-sans
    text-dark-blue
    border-2
    border-dark-blue;
    border-radius: 100px;
    padding: 14px 86px;
    font-size: 16px;
    font-weight: 400;
}

.resource-button:hover {
    @apply
    text-light-blue
    bg-dark-blue;
    transition: background .8s;
}
